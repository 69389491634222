import {JwtAxios} from "@/packaging";

export const dashboardAPI = {
    async GetCollectCount(){
        const responseModel = await JwtAxios.get(`/Dashboards/GetCollectCount`);
        return responseModel
    },
    async GetUploadCount(){
        const responseModel = await JwtAxios.get(`/Dashboards/GetUploadCount`);
        return responseModel
    },
    async GetRecommenders(){
        const responseModel = await JwtAxios.get(`/Dashboards/GetRecommenders`);
        return responseModel
    },
    async GetMasterAccount(){
        const responseModel = await JwtAxios.get(`/Dashboards/GetMasterAccount`);
        return responseModel
    },
    async GetMyPlan(){
        const responseModel = await JwtAxios.get(`/Dashboards/GetMyPlan`);
        return responseModel
    },
    async GetMyCollectionLimit(){
        const responseModel = await JwtAxios.get('/Dashboards/GetMyCollectionLimit')
        return responseModel
    },
    async SearchMyRelationAccount(model){
        const responseModel = await JwtAxios.post('/Dashboards/SearchMyRelationAccount',model)
        return responseModel
    },
    async SearchMyTaobaoInfo(model){
        const responseModel = await JwtAxios.post('/Dashboards/SearchMyTaobaoInfo',model)
        return responseModel
    }

}