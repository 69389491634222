import axios from "axios";
import { useAuthStore } from '@/stores';

export const JwtAxios = {
    get: request('GET'),
    post: request('POST'),
    delete: request('DELETE'),
    production_delete: productionRequest('DELETE')
};

function request(method) {
    return (path, body) => {
        let url = process.env.VUE_APP_API_URI + path;

        const requestOptions = {
            method,
            headers: authHeader()
        };
        if(method === 'GET' && body){
            url += "?" + jsonToQuery(body)
        } else if ((method === 'POST' || method === 'PUT' || method === 'DELETE' || method === 'PATCH') && body) {
            requestOptions.headers['Content-Type'] = 'application/json charset=utf-8';
            requestOptions.body = JSON.stringify(body);
        }

        return axios({
            method,
            url,
            data:body,
            headers: authHeader(),
        }).then(handleResponse).catch(handleError);
    }
}

function productionRequest(method) {
    return (path, body) => {
        let url = process.env.VUE_APP_PRODUCTION_URL + path;
        const requestOptions = {
            method,
            headers: authHeader()
        };
        if(method === 'GET' && body){
            url += "?" + jsonToQuery(body)
        } else if ((method === 'POST' || method === 'PUT' || method === 'DELETE' || method === 'PATCH') && body) {
            requestOptions.headers['Content-Type'] = 'application/json charset=utf-8';
            requestOptions.body = JSON.stringify(body);
        }

        return axios({
            method,
            url,
            data:body,
            headers: authHeader(),
        }).then(handleResponse).catch(handleError);
    }
}

function authHeader() {
    //return authheader with jwt if user is logged in and request is to the api url
    const { accessToken } = useAuthStore();

    if (accessToken !== null) {
        return { Authorization: `Bearer ${accessToken}` };
    } else {
        return {};
    }
}

function handleResponse(response) {
    const data = response.data

    if (response.status !== 200) {
        const { refreshToken, logout } = useAuthStore();
        if ([401, 403].includes(response.status) && refreshToken) {
            logout();
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

function handleError(error){
    let response = error.response
    if( response === undefined) { return false }

    let data = response.data
    if ([401, 403].includes(response.status)) {
        const { refreshToken, logout } = useAuthStore();
        if (refreshToken) {
            logout();
        }
    }
    return data
}

function jsonToQuery(param) {
    if (typeof param !== "object") {
        return false
    }

    let str = ""
    for (let key in param) {
        str += `${key}=${param[key]}&`
    }

    return str
}